.inputField input {
  width: 100%;
  padding: 10px 20px;
  font-size: 15px;
  border: none;
  border-top: 1px solid #ccc;
}

.bg-block {
  background: url("https://www.designbolts.com/wp-content/uploads/2012/12/Worn-Dots-White-Seamless-Pattern.jpg");
}
.close {
  position: absolute;
  right: 30px;
  top: 30px;
  font-family: sans-serif;
  cursor: pointer;
}
.close:hover {
  color: red;
}
.chatOption {
  background: #ffcd1a;
  padding: 10px;
}

.topBar h1 {
  font-size: 30px;
  color: #ee0c88;
}

.topBar {
  padding: 20px;
  /* box-shadow: 0 0 20px #ddd; */
  text-align: center;
}
.closeBtn {
  cursor: pointer;
}

label {
  display: block;
}
.selectLn {
  display: block;
  width: 200px;
  margin: 0 auto;
  margin-top: 40px;
}
.selectLn select {
  width: 100%;
  font-size: 15px;
  margin: 10px 0;
  border: 1px solid #ddd;
  padding: 5px;
}

.selectLanaguge {
  border: none;
  font-size: 15px;
  border-bottom: 2px solid #ddd;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 2rem;
  padding: 5px 20px;
}

.chat_widgets {
  position: fixed;
  z-index: 9999;
  right: 50px;
  bottom: 50px;
}
.botIcon img {
  width: 60px;
  height: 70px;
  margin-left: 15px;
}

.chat_widgets .header {
  background: #fff;
  color: #ffcd1a;
  padding: 30;
  text-align: left;
  padding: 20px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid #ddd;

}

.chatScreen {
  width: 400px;
  height: 500px;
  box-shadow: 0 0 10px #777;
  position: relative;
  background:white;
  overflow: hidden;
}

.chatOption {
  width: 100px;
}
.botIcon {
  display: flex;
  text-align: left;
  align-items: flex-end;
  transition: 0.5s ease-in-out;
}
.arrow {
    width: 0; 
    height: 0; 
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    position: absolute;
    left:100px;
    bottom:0px;
    border-left: 10px solid #ffcd1a;
  }
.list {
  list-style: none;
}
.list li {
  font-size: 12px;
  color: #000;
  font-weight: bold;
  padding-bottom: 10px;
  cursor: pointer;
}
.list li:hover {
  color: #fff;
}
.header{
  z-index:999;
  position: sticky;
  top:0;
}