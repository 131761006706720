
.chat_history{
    background: #fff;
    height: 560px;
    padding:30px;
    overflow: scroll;
}

.buttons button:hover{
    background: #625757;;
}
.buttons button{
    background: #ffb01a;
    color:#fff;
    border:none;
    font-size:15px;
    padding:10px 30px;
    margin:5px;
    border-radius: 5px;
}
.buttons button:hover{
    transform: scale(1.05);
}

.botReply{
    position: relative;
    margin-bottom: 10px;
    text-align:left;
}

.botReply .botIcon{
    position: absolute;
    width: 30px;
}

.userReply{
    direction: rtl;
}
.userText{
    display: block;
}
.userText p{
    background: #fff;
    padding: 10px 20px;
    display: inline-block;
    color:#141414;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;


}

.botText{
    display: block;
}
.botReply p{
    background: #e3e6ee;
    padding: 10px 20px;
    margin-left: 40px;
    display: inline-block;
    color:#333;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

}

.gallery{
    /* margin-left: 40px; */
    display: grid;
    grid-template-columns: 1fr 1fr;
}