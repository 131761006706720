.ya-card{
    max-width: 300px;
    background:#fff;
    box-shadow: 0px 1px 3px 0px rgba(142, 142, 142, 0.2), 0px 1px 1px 0px rgba(243, 243, 243, 0.14), 0px 2px 1px -1px rgba(204, 204, 204, 0.12);

}
.ya-card-img img{
    width: 100%;
    object-fit: cover;
    height: 100%;
}
.ya-card-img{
    position: relative;
    height: 200px;
    height: 200px;
}
.ya-card-ln{
    position: absolute;
    right: 0;
    background: #ec008c;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #fc6767, #ec008c);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #fc6767, #ec008c); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color:#fff;
    padding: 5px 30px;    
}
 h1{
    font-size: 18px;
}
.ya-card-content{
    padding:10px 20px;
}

.ya-card-content p{
    padding-top: 10px;
}

.ya-card-link{
    background: #ec008c;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #fc6767, #ec008c);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #fc6767, #ec008c); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color:#fff;
    padding: 10px 30px;
    text-align: center;
    font-weight: bold;
}

.ya-card-link a {
    text-decoration: none;
    color:#fff;
}

.ya-card-link:hover{
    background: #ec008ed7;  /* fallback for old browsers */

}